import { useState } from "react";
import "./Password.css";

const password = "roshneethebest";

export default function Password(props) {
  const [inputText, setInputText] = useState("");
  const [showError, setShowError] = useState(false);
  const handleSubmit = () => {
    if (inputText === password) {
      props.onComplete();
    } else {
      setShowError(true);
    }
  };

  const handleChange = (event) => {
    setInputText(event.target.value);
  };

  return (
    <div className="password-card">
      <h2>
        Bro I mean anyone can tuka-fy these MCQs. You gotta give me the secret
        code to proceed
      </h2>
      <div className="input-container">
        <input type="text" value={inputText} onChange={handleChange} />
        <button onClick={handleSubmit}>Submit</button>
        {showError && <p>Oops incorrect password</p>}
      </div>
    </div>
  );
}
