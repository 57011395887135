import "./Welcome.css"; // Import the CSS file for styling

const strangerButtonText = "I don't belong here";
const specialButtonText = "I'm that someone special";

export default function Welcome(props) {
  const handleButtonClick = (event) => {
    if (event.target.innerText === specialButtonText) {
      props.onComplete();
    }
  };
  return (
    <div className="welcome">
      <h1>Hi thereee</h1>
      <h2>
        This website is for someone special. If you're not that someone special,
        please close this tab before the cat gets killed :)
      </h2>
      <p className="curiosity">
        (curiosity kills the cat, if you didn't get it the first time).
      </p>
      <h2>
        {" "}
        If you're that someone special, you'll have to first answer a few
        questions to prove it. Sorry for the inconvenience.
      </h2>
      <button onClick={handleButtonClick} className="stranger-button">
        {strangerButtonText}
      </button>
      <button onClick={handleButtonClick} className="special-button">
        {specialButtonText}
      </button>
    </div>
  );
}
