import { useState } from "react";

import "./App.css";
import Card from "./Card";
import WordSearchGame from "./WordSearchGame";
import Quiz from "./Quiz";
import Welcome from "./Welcome";
import "./style.css";
import Password from "./Password";

function App() {
  const [showWelcome, setShowWelcome] = useState(true);
  const [showQuiz, setShowQuiz] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [showWordSearch, setShowWordSearch] = useState(false);

  const handleWelcomeComplete = () => {
    setShowWelcome(false);
    setShowQuiz(true);
  };

  const handleQuizComplete = () => {
    setShowQuiz(false);
    setShowPassword(true);
  };

  const handlePasswordComplete = () => {
    setShowPassword(false);
    setShowCard(true);
  };

  const handleCardComplete = () => {
    setShowCard(false);
    setShowWordSearch(true);
  };

  return (
    <div className="App background-image">
      {/* <Password /> */}
      {showWelcome && <Welcome onComplete={handleWelcomeComplete} />}
      {showQuiz && <Quiz onComplete={handleQuizComplete} />}
      {showPassword && <Password onComplete={handlePasswordComplete} />}
      {showCard && <Card onComplete={handleCardComplete} />}
      {showWordSearch && <WordSearchGame />}
    </div>
  );
}

export default App;
