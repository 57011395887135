import "./Card.css";

export default function Card(props) {
  function handleTextClick() {
    props.onComplete();
  }
  return (
    <div className="birthdayCard">
      <div className="cardFront">
        <h3 className="happy">HAPPY BIRTHDAY ROSHU!</h3>
        <div className="balloons">
          <div className="balloonOne" />
          <div className="balloonTwo" />
          <div className="balloonThree" />
          <div className="balloonFour" />
          <div className="balloonFive" />
          <div className="balloonSix" />
        </div>
      </div>
      <div className="cardInside">
        <p className="card-text">
          Dear love,
          <br />
          <br />
          Happy birthday!
          <br />
          <br />I pray to Allah to bless you with the best of his blessings.
          Just like I've been blessed with the best of his blessing, you!
          <br />
          <br />I have a birthday game for you, wanna play?
        </p>
        <button onClick={handleTextClick}>Ofcourse!!</button>
      </div>
    </div>
  );
}
