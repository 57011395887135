import { useState } from "react";
import McqCard from "./McqCard";

export default function Quiz(props) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showNextQuestion, setShowNextQuestion] = useState(false);

  const questions = [
    {
      question: "Whose stomach makes the weirdest sounds?",
      options: ["Irtaza", "Roshnee", "Lion King", "Humpty Dumpty"],
      correctOptions: [1],
    },
    {
      question: "Who is the sweetest person ever?",
      options: ["Irtaza", "Irtaza", "Irtaza", "Irtaza"],
      correctOptions: [0, 1, 2, 3],
    },
    {
      question: "Who do you love the most",
      options: ["Dada", "Dadi", "Nana", "Nani"],
      correctOptions: [0],
    },
    {
      question: "Coffee has a strong relationship with",
      options: ["Sleep", "Potty", "Pishi", "Milk"],
      correctOptions: [1],
    },
  ];

  const handleQuestionAnswered = (isCorrect) => {
    if (isCorrect) {
      setShowNextQuestion(true);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  return (
    <div className="quiz">
      {currentQuestionIndex < questions.length ? (
        <McqCard
          question={questions[currentQuestionIndex].question}
          options={questions[currentQuestionIndex].options}
          correctOptions={questions[currentQuestionIndex].correctOptions}
          onAnswered={handleQuestionAnswered}
        />
      ) : (
        props.onComplete()
      )}
      {/* {showNextQuestion && currentQuestionIndex < questions.length && (
        <button
          onClick={() => {
            setShowNextQuestion(false);
          }}
        >
          Next Question
        </button>
      )} */}
    </div>
  );
}
