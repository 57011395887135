// src/WordSearchGrid.js
import React, { useState, useEffect } from "react";
import Video from "./Video";
import "./WordSearchGame.css";

// Import or specify file paths here
import gridFilePath from "./puzzle.txt";
import wordsToVideoFilePath from "./wordsToVideo.txt";

export default function WordSearchGame() {
  const [grid, setGrid] = useState([]);
  const [wordsToVideo, setWordsToVideo] = useState([]);
  const [foundWords, setFoundWords] = useState([]);
  const [selectedCells, setSelectedCells] = useState([]);
  const [previouslySelectedCells, setPreviouslySelectedCells] = useState([]);
  const [popupWord, setPopupWord] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    // Function to read a text file asynchronously
    const readTextFile = (filePath, callback) => {
      fetch(filePath)
        .then((response) => response.text())
        .then((content) => {
          callback(content);
        })
        .catch((error) => {
          console.error(`Error reading file ${filePath}:`, error);
        });
    };

    // Read the grid file
    readTextFile(gridFilePath, (content) => {
      const gridArray = content
        .trim()
        .split("\n")
        .map((row) => row.split(" "));
      setGrid(gridArray);
    });

    // Read the answers file
    readTextFile(wordsToVideoFilePath, (content) => {
      const wordsToVideo = [];
      content
        .trim()
        .split("\n")
        .forEach((line) => {
          const [word, videoLink] = line.split("<>");
          wordsToVideo.push({ word: word, video: videoLink });
        });
      setWordsToVideo(wordsToVideo);
    });
  }, []);

  const handleCellClick = (rowIndex, colIndex) => {
    const clickedCell = { row: rowIndex, col: colIndex };
    const isPreviouslySelected = previouslySelectedCells.some(
      (cell) => cell.row === rowIndex && cell.col === colIndex
    );
    // Construct the selected word based on selected cell positions
    var newSelectedCells = [];
    var newPreviouslySelectedCells = [];
    if (isPreviouslySelected) {
      newSelectedCells = selectedCells.filter(
        (cell) => cell.row !== rowIndex || cell.col !== colIndex
      );
      newPreviouslySelectedCells = previouslySelectedCells.filter(
        (cell) => cell.row !== rowIndex || cell.col !== colIndex
      );
    } else {
      newSelectedCells = [...selectedCells, clickedCell];
      newPreviouslySelectedCells = [...previouslySelectedCells, clickedCell];
    }

    // Update selected cells immediately
    setSelectedCells(newSelectedCells);
    setPreviouslySelectedCells(newPreviouslySelectedCells);
  };

  useEffect(() => {
    const findWord = (word) => {
      // Implement the logic to check if the word exists in the grid based on selected cell positions.
      // If the word is found, add it to the `foundWords` state and set the `popupWord` state.

      // Convert the word's characters to an array of positions [{row, col}]
      const wordPositions = word
        .split("")
        .map((char, index) => selectedCells[index]);

      // Check if the word's positions match the selected cell positions
      const isWordFound = wordPositions.every((position) => {
        return selectedCells.some(
          (cell) => cell.row === position.row && cell.col === position.col
        );
      });

      if (isWordFound) {
        setFoundWords((prevFoundWords) => [...prevFoundWords, word]);
        setPopupWord(word);
        setVideoUrl(wordsToVideo.find((item) => item.word === word).video);
        setSelectedCells([]); // Reset selected cells
      }
    };

    // Check if the selected word exists in the list of words
    const selectedWord = selectedCells
      .map(({ row, col }) => grid[row][col])
      .join("");
    if (wordsToVideo.some((item) => item.word === selectedWord)) {
      setTimeout(() => {
        findWord(selectedWord, () => {
          setSelectedCells([]); // Reset selected cells in the callback
        });
      }, 0);
    }
  }, [selectedCells, grid, wordsToVideo]); // Run this effect whenever selectedCells changes

  // Function to style selected and previously selected cells
  const getCellStyle = (rowIndex, colIndex) => {
    // Check if the cell is in the list of selected cell positions
    const isSelected = previouslySelectedCells.some(
      (cell) => cell.row === rowIndex && cell.col === colIndex
    );

    // Apply a style to the selected cell and previously selected cells
    if (isSelected) {
      return { backgroundColor: "yellow" };
    }
    return {};
  };

  return (
    <div className="word-search-container">
      <p className="goal">
        Hey champ! You got to find {wordsToVideo.length} words in the following
        grid. Horizontal, vertical or diagnol.
      </p>
      {videoUrl && (
        <div className="video-container">
          <Video url={videoUrl} />
          <button onClick={() => setVideoUrl("")}>Close</button>
        </div>
      )}
      <div className="grid">
        {grid.map((row, rowIndex) => (
          <div key={rowIndex} className="grid-row">
            {row.map((cell, colIndex) => (
              <div
                key={colIndex}
                className="grid-cell"
                style={getCellStyle(rowIndex, colIndex)}
                onClick={() => handleCellClick(rowIndex, colIndex)}
              >
                {cell}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="found-words">
        {foundWords.map((word, index) => (
          <div key={index} className="found-word">
            {word}
          </div>
        ))}
      </div>
      {/* {popupWord && (
        <div className="popup">
          <p>{`You found the word: ${popupWord}`}</p>
          <button onClick={() => setPopupWord("")}>Close</button>
        </div>
      )} */}
      {
        // check if game ended
        foundWords.length === wordsToVideo.length && (
          <div className="popup">
            <p>"You found all the words Rockstar!!"</p>
            <button>Close</button>
          </div>
        )
      }
    </div>
  );
}
