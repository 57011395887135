import { useState } from "react";
import "./McqCard.css";

export default function McqCard(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showResult, setShowResult] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(parseInt(event.target.value));
  };

  const isOptionCorrect = (optionIndex) => {
    return props.correctOptions.includes(optionIndex);
  };

  const getResultMessage = () => {
    if (showResult) {
      return "Oops! That's not correct.";
    }
    return "";
  };

  const handleSubmit = () => {
    if (isOptionCorrect(selectedOption)) {
      props.onAnswered(true); // Notify the parent component that the answer is correct
      setShowResult(false);
    } else {
      setShowResult(true);
    }
  };

  return (
    <div className="question-card">
      <h2>{props.question}</h2>
      <form>
        {props.options.map((option, index) => (
          <div key={index} className="option">
            <input
              type="radio"
              id={`option${index}`}
              name="options"
              value={index}
              checked={selectedOption === index}
              onChange={handleOptionChange}
            />
            <label htmlFor={`option${index}`}>{option}</label>
          </div>
        ))}
      </form>
      <button onClick={handleSubmit}>Submit</button>
      <p className="result-message">{getResultMessage()}</p>
    </div>
  );
}
