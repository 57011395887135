import React from "react";
import ReactPlayer from "react-player";

function Video(pros) {
  return (
    <div>
      <ReactPlayer url={pros.url} width="800px" height="600px" controls />
    </div>
  );
}

export default Video;
